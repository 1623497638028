.newNavbar {
  height: 130px;
  padding: 0 40px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  margin: 0 3%;
  width: 100%;
  border-radius: 156px;
}

.new-nav-items {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding-top: 5px;
}

.new-nav-item {
  font-family: whyte;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 112%;
  color: white;
  text-decoration: none;
  position: relative;
  opacity: 0.9;
}

.new-nav-item:hover {
  opacity: 1;
}

.new-nav-item::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background: white;
  transition: all 0.45s;
}

.new-nav-item:hover::before {
  width: 100%;
}

.newNavbar > .nav-toggle {
  display: none;
}

.new-get-started {
  font-family: whyte;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 202px;
  height: 73px;
  border: 1px solid #fbc4c5;
  border-radius: 77px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 112%;
  color: white;
  text-decoration: none;
  position: relative;
  padding-top: 5px;
}

@media screen and (max-width: 950px) {
  .new-nav-item {
    font-size: 18px;
  }
}

@media screen and (max-width: 850px) {
  .newNavbar {
    justify-content: space-between;

    height: 100px;
    border-radius: 0;
    margin: 0 0 ;
    padding: 0 0px;

    /* border-bottom : 0.1px solid #BFBFBF; */
  }
  .newNavbar > .new-nav-items {
    position: absolute;
    background-image: url("../../assets/homePage/section-1-background.png");
    background-repeat: no-repeat;
    background-size: cover;
    top: 95px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    left: 0;
    padding: 60px 10%;
    width: 100%;
    /* all display height 100vh */
    font-size: 20px;
    height: 120vh;
    z-index: 999;
    transform: translateX(-100%);
    transition: all 0.45s;
    gap: 30px;
  }

  .new-nav-item {
    font-weight: 700;
    font-size: 16px;
  }

  .new-nav-item::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background: white;
    transition: all 0.45s;
  }

  .new-nav-item-ar::before {
    content: "";
    position: absolute;
    right: 0 !important;
    bottom: 0;
    width: 0;
    height: 2px;
    background: white;
    transition: all 0.45s;
  }
  .new-nav-item:hover {
    opacity: 1;
  }

  .new-nav-item:hover::before {
    width: 100%;
  }

  .newNavbar > .new-nav-items.open {
    transform: translateX(0);
  }
  .newNavbar > .nav-toggle {
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .newNavbar > .nav-toggle > .bar {
    position: relative;
    width: 32px;
    height: 2px;
    background: white;
  }
  .nav-toggle > .bar::before,
  .nav-toggle > .bar::after {
    content: "";
    position: absolute;
    height: 2px;
    background: white;
    border-radius: 2px;
    transition: all 0.45 ease-in-out;
  }

  .nav-toggle > .bar::before {
    width: 32px;
    transform: translateY(-8px);
    right: 0;
  }
  .nav-toggle > .bar::after {
    width: 32px;
    transform: translateY(8px);
  }

  .nav-toggle.open > .bar {
    transform: translateX(-40px);
    background: transparent;
  }

  .nav-toggle.open > .bar::before {
    width: 32px;
    transform: rotate(45deg) translate(26px, -26px);
  }

  .nav-toggle.open > .bar::after {
    width: 32px;
    transform: rotate(-45deg) translate(26px, 26px);
  }
  .new-get-started {
    width: 132px;
    height: 45px;
    border: 1px solid #fbc4c5;
    border-radius: 77px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    padding-top: 5px;
    font-weight: 500;
    line-height: 112%;
    color: white;
    text-decoration: none;
    position: relative;
  }
}
