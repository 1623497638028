html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "whyte";
  src: url("../../assets/fonts/WhyteInktrap-Regular.ttf");
}

@font-face {
  font-family: "whyte-heavy";
  src: url("../../assets/fonts/WhyteInktrap-Heavy.ttf");
}
@font-face {
  font-family: "whyte-medium";
  src: url("../../assets/fonts/WhyteInktrap/WhyteInktrap-Book.otf");
}


@font-face {
  font-family: whyte-bold;
  src: url("../../assets/fonts/WhyteInktrap-Medium.otf");
}

@font-face {
  font-family: gotham-light;
  src: url("../../assets/fonts/Gotham-Font/GothamLight.ttf");
}

@font-face {
  font-family: gotham;
  src: url("../../assets/fonts/Gotham-Font/GothamBook.ttf");
}

@font-face {
  font-family: gotham-bold;
  src: url("../../assets/fonts/Gotham-Font/Gotham-Bold.otf");
}

@font-face {
  font-family: gotham-medium;
  src: url("../../assets/fonts/Gotham-Font/GothamMedium.ttf");
}


.whyte {
  font-family: whyte;
}
.whyte-heavy {
  font-family: whyte-heavy !important;
}

.whyte-bold {
  font-family: whyte-bold;
}

.whyte-medium {
  font-family: whyte-medium;
}

.gotham-light {
  font-family: gotham-light;
}

.gotham {
  font-family: gotham;
}
.gotham-bold {
  font-family: gotham-bold;
}

.gotham-medium {
  font-family: gotham-medium;
}

.section-1 {
  min-height: 100vh;
  background-image: url("../../assets/homePage/section-1-background.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.Navbar {
  /* position: sticky;
    top: 0; */
  padding-top: 40px;
  /* height: 104px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  margin: 0 8%;
}




.nav-items {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding-top: 20px;
}

.nav-item {
  font-family: whyte;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 112%;
  color: white;
  text-decoration: none;
  position: relative;
  opacity: 0.9;
}

.nav-item:hover {
  opacity: 1;
}

.nav-item::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background: white;
  transition: all 0.45s;
}

.nav-item:hover::before {
  width: 100%;
}

.Navbar > .nav-toggle {
  display: none;
}

.get-started {
  font-family: whyte;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 202px;
  height: 73px;
  border: 1px solid #fbc4c5;
  border-radius: 77px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 112%;
  color: white;
  text-decoration: none;
  position: relative;
  padding-top: 5px;
}

@media screen and (max-width: 950px) {
  .nav-item {
    font-size: 18px;
  }
}

@media screen and (max-width: 850px) {
  .Navbar {
    justify-content: space-between;

    height: 80px;

    /* border-bottom : 0.1px solid #BFBFBF; */
  }
  .Navbar > .nav-items {
    position: absolute;
    background-image: url("../../assets/homePage/section-1-background.png");
    background-repeat: no-repeat;
    background-size: cover;
    top: 95px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    left: 0;
    padding: 60px 10%;
    width: 100%;
    /* all display height 100vh */
    font-size: 20px;
    height: 120vh;
    z-index: 40;
    transform: translateX(-100%);
    transition: all 0.45s;
    gap: 30px;
  }

  .nav-item {
    font-weight: 700;
    font-size: 16px;
  }

  .nav-item::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background: white;
    transition: all 0.45s;
  }

  .nav-item-ar::before {
    content: "";
    position: absolute;
    right: 0 !important;
    bottom: 0;
    width: 0;
    height: 2px;
    background: white;
    transition: all 0.45s;
  }
  .nav-item:hover {
    opacity: 1;
  }

  .nav-item:hover::before {
    width: 100%;
  }

  .Navbar > .nav-items.open {
    transform: translateX(0);
  }
  .Navbar > .nav-toggle {
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .Navbar > .nav-toggle > .bar {
    position: relative;
    width: 32px;
    height: 2px;
    background: white;
  }
  .nav-toggle > .bar::before,
  .nav-toggle > .bar::after {
    content: "";
    position: absolute;
    height: 2px;
    background: white;
    border-radius: 2px;
    transition: all 0.45 ease-in-out;
  }

  .nav-toggle > .bar::before {
    width: 32px;
    transform: translateY(-8px);
    right: 0;
  }
  .nav-toggle > .bar::after {
    width: 32px;
    transform: translateY(8px);
  }

  .nav-toggle.open > .bar {
    transform: translateX(-40px);
    background: transparent;
  }

  .nav-toggle.open > .bar::before {
    width: 32px;
    transform: rotate(45deg) translate(26px, -26px);
  }

  .nav-toggle.open > .bar::after {
    width: 32px;
    transform: rotate(-45deg) translate(26px, 26px);
  }
  .get-started {
    width: 132px;
    height: 45px;
    border: 1px solid #fbc4c5;
    border-radius: 77px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    padding-top: 5px;
    font-weight: 500;
    line-height: 112%;
    color: white;
    text-decoration: none;
    position: relative;
  }
}

/* section 2 */
/*   
  .section-2-img {
  
    height: 100%;
    background-image: url();
    background-repeat: no-repeat;
  } */

/*  */







.section-7 {
  min-height: 100vh;
  background-image: url("../../assets/homePage/section-2-frame.png");
  background-repeat: no-repeat;
  background-size: fill;
  background-position: center;
  position: relative;
  top: -130px;
  left:0px;
  z-index: 0;
}

@media screen and (max-width: 850px) {
  .section-7 {
    min-height: 100vh;
    background-image: url("../../assets/homePage/section-2-frame.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    top: -130px;
    left:0px;
    z-index: 0;
  }
}

.footer {
  width: 100%;
  min-height: 665px;
  background-image: url("../../assets/homePage/footer.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.get-startedd {
  z-index: 999 !important;
}


.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper{

  border-radius: 41px;
  background-color: #FFFFFF;
  max-height: 928px;
  max-width: 1463px;
}



.navbar-item {
  font-family: whyte;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 112%;
  color: white;
  text-decoration: none;
  position: relative;
  opacity: 1;
}

.navbar-item:hover {
  opacity: 1;
}

.navbar-item::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 3px;
  background: #FBC4C5;
  transition: all 0.45s;
}
.navbar-item:hover::before {
  width: 100%;
}



#Label::after {
  content: "";
  width: 35px;
  height: 35px;
  background-color: #e8f5f7;
  position: absolute;
  border-radius: 70px;
  top: 2.5px;
  left: 2.5px;
  transition: 0.5s;
}

input:checked + label:after {
  left: calc(100% - 10px);
  transform: translateX(110%);
  }


  @media screen and (max-width: 850px) {
  
    #Label::after {
      content: "";
      width: 25px;
      height: 25px;
      background-color: #e8f5f7;
      position: absolute;
      border-radius: 70px;
      top: 2.5px;
      left: 2.5px;
      transition: 0.5s;
    }
    
    input:checked + label:after {
      left: calc(100% - 10px);
      transform: translateX(110%);
      }
  }


  @media screen and (min-width: 1550px) {
  
    .navbar-item {
      font-size: 20px;
    }
  }